import { trackBigBrainPageView } from "/services/bigbrain-service";
import { saveCouponCookie } from "services/cookies/coupon-cookies-service";
import { setClusterIdIfNeeded, setClusterForUser } from "/services/clusters-service";
import { setLocaleIdIfNeeded } from "/services/locales-service";
import { saveMarketingCookies, saveMarketingTemplateBoardIdsCookie, setCampaignProductCookieIfNeeded } from "/services/cookies/marketing-cookies-service";
import { trackPagePerformance } from "/services/page-performance-tracking-events-service";
import Router from "next/router";
import { saveExtendTrialCookie } from "services/cookies/extended-trial-cookies-service";
import { setSkipQuestionsCookieIfNeeded } from "./platform-cookie-sync-service";
import { initDomObserving } from "./dom-observer-service";
import { initPageUsageDurationTracking } from "./page-usage-duration-tracking";
import { CLUSTER_TYPE, USE_CASE_TYPE } from "/constants/clusters/types.js";
import { fireBigbrainVisitorExperimentSyncEvent } from "services/bigbrain-visitor-experiment-sync-service";
export const initHeaderScripts = function({ coupon, extendTrialCookie, marketingTemplateBoardIds, clusterId, subClusterId, clusterIdSource, subClusterIdSource, localeId, template, skipClustersQuestion, enablePageUsageDurationTracking, isProduction }) {
    const startedAt = new Date().getTime();
    saveMarketingCookies();
    setCampaignProductCookieIfNeeded();
    saveCouponCookie(coupon);
    saveExtendTrialCookie(extendTrialCookie);
    saveMarketingTemplateBoardIdsCookie(marketingTemplateBoardIds);
    setClusterIdIfNeeded(clusterId, subClusterId, {
        sendBigBrainEvent: true
    });
    setClusterForUser(clusterId, CLUSTER_TYPE, clusterIdSource);
    setClusterForUser(subClusterId, USE_CASE_TYPE, subClusterIdSource, {
        parent_cluster_id: clusterId
    }); //currently sub-cluster available only when setting also cluster
    setLocaleIdIfNeeded(localeId, {
        sendBigBrainEvent: true
    });
    setSkipQuestionsCookieIfNeeded(skipClustersQuestion);
    fireBigbrainVisitorExperimentSyncEvent();
    initDomObserving();
    if (isProduction && enablePageUsageDurationTracking) {
        initPageUsageDurationTracking(startedAt);
    }
    window.addEventListener("load", ()=>{
        trackPagePerformance({
            template
        });
    });
    Router.onRouteChangeComplete = (url)=>{
        trackPageView(url, clusterId, subClusterId);
    }; //Track changes on client routing
};
export const trackPageView = function(url, clusterId, subClusterId) {
    trackBigBrainPageView(url, clusterId, subClusterId);
};
